@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	scroll-behavior: smooth;
}

body {
	font-family: "Ubuntu", sans-serif;
	min-height: 100dvh;
}

.archivo-black-font {
	font-family: "Archivo Black", sans-serif;
}
