.slick-dots .slick-active > button {
	background-color: white;
}

.slick-active.slick-slide .slider-bg {
	animation: scale 12s infinite alternate ease;
}

@keyframes scale {
	from {
		transform: scale(1);
	}
	to {
		transform: scale(1.15);
	}
}
